var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "monitorViode" }, [
    _vm._v("\n  12312312321\n  " + _vm._s(_vm.url) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }